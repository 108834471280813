<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <div class="head">
        <div class="left">
          <b class="left_tit">商品列表</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>
      <div class="screen">
        <div class="screen_tit">
          <div class="screen_left">
            <i class="el-icon-search"></i>
            数据列表
          </div>
          <div class="screen_right">
            <el-button size="mini" @click="addGoods">添加商品</el-button>
          </div>
        </div>
      </div>
      <!-- 添加商品的模态框 -->
      <div class="popContainer" v-show="addShow">
        <transition name="el-fade-in">
          <div  class="transition-box">
            <div class="act_tit">
              添加/编辑商品
              <el-button
                style="float: right; padding: 3px 10px"
                type="text"
                @click="closeAdd()"
                >关闭按钮</el-button
              >
            </div>
            <div>筛选条件：</div>
            <div style="margin-top: 5px">
              <el-input
                v-model="searchIpt"
                placeholder="商品名称/商品货号"
                style="width: 200px"
                size="small"
              ></el-input>
              <el-button
                @click="searchBtn"
                style="padding: 3px 10px"
                type="text"
                >搜索按钮</el-button
              >
              当前已选择{{selectGoods}}件商品
            </div>
            <div style="margin-top: 10px">
              <div v-show="merchantShow">
                选择商户：
                <el-select
                  v-model="business_id"
                  @change="seletChange"
                  size="small"
                  placeholder="请选择商户"
                >
                  <el-option
                    v-for="item in businessList"
                    :key="item.business_name"
                    :label="item.business_name"
                    :value="item.business_id"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-button @click="addGoodsList" style="float: right"
                >确认添加</el-button
              >
            </div>
            <div class="table" style="margin-top: 2px; text-align: center">
              <el-table
                height="380px"
                ref="multipleTable"
                :data="addTableData"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
              >
                <el-table-column label="商品名称" width="200" align="center">
                  <template slot-scope="scope" align="center">{{
                    scope.row.gname
                  }}</template>
                </el-table-column>
                <el-table-column label="货号" width="180" align="center">
                  <template slot-scope="scope">{{ scope.row.gcode }}</template>
                </el-table-column>
                <el-table-column
                  prop="selling_price"
                  label="价格"
                  show-overflow-tooltip
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  type="selection"
                  lable="操作"
                  width="55"
                  align="center"
                >
                </el-table-column>
              </el-table>
              <el-pagination
                style="margin-top: 15px"
                :page-sizes="[10, 20, 30, 40]"
                layout="total,  prev, pager, next, jumper"
                :total="goodsListTotal"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </transition>
      </div>
      <div class="table" style="width: 85%; margin-left: 200px">
        <el-table :data="activeGoodsList" border style="width: 100%">
          <el-table-column
            prop="active_goods_id"
            label="编号"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品名称"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="business_name"
            label="商户"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="s_name"
            label="门店"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="gcode" width="200" label="货号" align="center">
          </el-table-column>
          <el-table-column
            prop="selling_price"
            label="商品价格"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="kill_price" label="秒杀价格" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.kill_price"></el-input>
            </template>
            <el-input v-model="input"></el-input>
          </el-table-column>
          <el-table-column prop="kill_number" label="秒杀数量" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.kill_number"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="stock" label="剩余数量" align="center">
          </el-table-column>
          <el-table-column prop="sort" label="排序" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.sort"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="
                  editPrice(
                    scope.row.active_goods_id,
                    scope.row.kill_price,
                    scope.row.kill_number,
                    scope.row.sort
                  )
                "
                >保存</el-button
              >
              <el-button
                size="mini"
                @click="delGoodsactive(scope.row.active_goods_id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @current-change="CurrentChange"
          :current-page="currentPage2"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, prev, pager, next, jumper"
          :total="aglTotal"
        >
        </el-pagination>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";
export default {
  // props:[],
  data() {
    return {
      input: "",
      top1: "7-0",
      top2: ["7"],
      flag: 1,
      active_id: "",
      merchantShow: false, //不同权限下的展示
      business_id: "", //商户id
      addShow: false, //添加商品框的显示隐藏
      searchIpt: "", //搜索商品的输入框
      //选择商户的下拉框数据
      businessList: [],
      business_name: "",
      //添加时的商品表格
      addTableData: [],
      goodsListTotal: 1, //添加时表格总长度
      //添加商品参数
      dataSelections: "",
      //所有活动商品列表数据
      activeGoodsList: [],
      selectGoods:0,//选中的商品条数
      goods_ids: [], //已经添加过的商品id
      aglTotal: 10, //活动商品条数
      limited_number: "", //限购数量
      currentPag: 1, //添加商品框列表的页码
      currentPage2: 1, //活动商品列表的页码
    };
  },
  methods: {
    //刷新页面按钮
    refresh() {
      location.reload();
    },
    //添加商品
    addGoods() {
        this.business_id = ''
      console.log(this.business_id);
      //请求商户列表
      this.$request.businessList({}).then((res) => {
        console.log("商户列表", res.data);
        this.businessList = res.data;
      });
      //门店列表
      this.addShow = true;

      console.log(this.goods_ids);
      if (this.goods_ids != []) {
        this.goods_ids = JSON.stringify(this.goods_ids);
      }
      // this.goods_ids = JSON.stringify(this.goods_ids);
      console.log(this.goods_ids);
      this.$request
        .goodsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          search: "", //搜索值
          business_id: "", //商户门店ID，超管传 其余传空
          page: this.currentPag,
          limit: 10,
          goods_ids: this.goods_ids,
        })
        .then((res) => {
          console.log("所有活动商品数据", res.data);
          this.addTableData = res.data.data;
          this.goodsListTotal = res.data.count; //搜索完成列表的数据条数
        });
    },
    //搜索按钮
    searchBtn() {
      this.$request
        .goodsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          search: this.searchIpt, //搜索值
          business_id: this.business_id, //商户门店ID，超管传 其余传空
          page: this.currentPag,
          limit: 10,
          goods_ids: this.goods_ids,
        })
        .then((res) => {
          console.log("搜索的商品数据", res.data.data);
          // if (res.code == 0) {
          this.addTableData = res.data.data;
          this.aglTotal = res.data.cunt;
        });
    },
    //选中商户事件
    seletChange() {
      console.log("选中门店发起查询");
      console.log(this.business_id);
    
      this.$request
        .goodsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          search: this.searchIpt, //搜索值
          business_id: this.business_id, //商户门店ID，超管传 其余传空
          page: this.currentPag,
          limit: 10,
          goods_ids: this.goods_ids,
        })
        .then((res) => {
          this.addTableData = res.data.data;
        });
    },
    //关闭添加商品窗口
    closeAdd() {
      this.addShow = false;
      this.$request
            .activeGoodsList({
              token: this.$storage.getLocal("token"),
              active_id: this.active_id,
              type: 1,
              page: this.currentPag2,
              limit: 10,
            })
            .then((res) => {
              console.log("111111111111", res);
              this.activeGoodsList = res.data.data;
              this.goods_ids = res.data.goods_ids;
            });
      
    },
    //表格多选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选中的表格数据
    handleSelectionChange(val) {
      this.dataSelections = val;
      console.log(val.length);
      this.selectGoods = val.length;
    },
    //添加商品列表
    addGoodsList() {
      let val = this.dataSelections;
      for (var i = 0; i < val.length; i++) {
        val[i].active_id = this.active_id;
        val[i].type = 1;
        delete val[i].gname;
        delete val[i].gcode;
        delete val[i].selling_price;
      }
      this.dataSelections = JSON.stringify(val);
      this.$request
        .setStoreGoods({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          data: this.dataSelections,
          active_goods_id: "",
          business_id: this.business_id,
        })
        .then((res) => {
          this.addShow = false;
          console.log(res.data.data);
          this.$request
            .activeGoodsList({
              token: this.$storage.getLocal("token"),
              active_id: this.active_id,
              type: 1,
              page: this.currentPag2,
              limit: 10,
            })
            .then((res) => {
              console.log("111111111111", res);
              this.activeGoodsList = res.data.data;
              this.goods_ids = res.data.goods_ids;
            });
        });
    },
    //编辑秒杀价格、数量、限购
    editPrice(active_goods_id, kill_price, kill_number, sort) {
      console.log(active_goods_id, kill_price, kill_number, sort);
      this.$request
        .setStoreGoods({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          active_goods_id: active_goods_id,
          kill_price: kill_price,
          kill_number: kill_number,
          limited_number: this.limited_number,
          sort: sort,
        })
        .then((res) => {
			if(res.code==0)
			{
				// this.$message('秒杀规则设置成功');
				  this.$message({
				          message: '秒杀规则设置成功',
				          type: 'success'
				        });
				this.$request
				  .activeGoodsList({
				    token: this.$storage.getLocal("token"), //登陆进来的tooken
				    active_id: this.active_id,
				    type: 1,
				    page: this.currentPag,
				    limit: 10,
				  })
				  .then((res) => {
				    this.activeGoodsList = res.data.data;
				  });
			}
   
        });
    },
    //商品活动删除
    delGoodsactive(active_goods_id) {
      console.log(active_goods_id);
      this.$request
        .deleteActiveGoods({
          token: this.$storage.getLocal("token"),
          active_goods_id: active_goods_id,
        })
        .then((res) => {
          console.log(res);
		
			  
			  this.$request
			    .activeGoodsList({
			      token: this.$storage.getLocal("token"), //登陆进来的tooken
			      active_id: this.active_id,
			      type: 1,
			      page: this.currentPag2,
			      limit: 5,
			    })
			    .then((res) => {
			      this.activeGoodsList = res.data.data;
			    });
		  
			  
       
        });
    },
    //添加商品时的分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPag = `${val}`;
      console.log(this.currentPag);
      this.$request
        .goodsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          search: "", //搜索值
          business_id: 8, //商户门店ID，超管传 其余传空
          page: this.currentPag,
          limit: 10,
        })
        .then((res) => {
          console.log("所有活动商品数据", res.data);
          this.addTableData = res.data.data;
        });
    },
    //活动商品列表的分页
    CurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPag2 = `${val}`;
      console.log(this.currentPag);
      this.$request
        .activeGoodsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          active_id: this.active_id,
          type: 1,
          page: this.currentPag2,
          limit: 10,
        })
        .then((res) => {
          this.activeGoodsList = res.data;
        });
    },
  },
  created() {
    //商品活动列表展示
    this.active_id = this.$route.query.active_id;
    this.business_id = this.$route.query.business_id;
    this.admin_type = this.$storage.getLocal("type");
    if (this.admin_type == 1) {
      this.merchantShow = true;
    }
    this.$request
      .activeGoodsList({
        token: this.$storage.getLocal("token"), //登陆进来的tooken
        active_id: this.active_id,
        type: 1,
        page: this.currentPag2,
        limit: 10,
      })
      .then((res) => {
        console.log("111111111111111111所有活动商品数据", res.data);
        this.activeGoodsList = res.data.data;
        this.aglTotal = res.data.count;
        this.goods_ids = res.data.goods_ids;
        console.log(res.data.goods_ids);
      });
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}
.popContainer {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.transition-box {
  position: absolute;
  z-index: 2;
  top: 100px;
  left: 27%;
  width: 800px;
  height: 600px;
  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: left;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(242, 242, 242);
  margin-top: -40px;
  margin-left: -20px;
  padding-left: 15px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}
</style>